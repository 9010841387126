import React from "react";
import "./ride-app.css"; // Import the CSS file
import icon from "../../../assets/images/disc.png"; // Import the icon image
import AppStore from '../../../assets/images/appstore-btn.png'
import PlayStore from '../../../assets/images/playstore-btn.png'


const ForunRide = () => {
  return (
    <div className="forun-ride-container">
      <div className="text-section">
        <h2 className="forun-ride-heading">NEW UPDATE</h2>
        <h1 className="forun-ride-subhead">Make your Travel Easier with Forun Ride Service</h1>
        <p className="forun-ride-text">
        Getting somewhere has never been so easier and convenient. ForunPoint’s Ride Service is here to revolutionize your travel experience. Whether you’re heading to work, meeting friends, or running errands, we’ve got you covered with:
        </p>

        <ul className="forun-ride-list">
          <li> <img src={icon} alt="icon" className="list-icon" />
          Affordable Rates: Enjoy budget-friendly rides without compromising on quality.</li>
          <li><img src={icon} alt="icon" className="list-icon" />
          Reliable Drivers: Travel with experienced, verified drivers committed to your safety and comfort.</li>
          <li><img src={icon} alt="icon" className="list-icon" />
          Quick Bookings: Say goodbye to long waits—get a ride in minutes with just one tap.</li>
          <li><img src={icon} alt="icon" className="list-icon" />
          Flexible Options: Choose from a range of vehicle types to suit your needs.</li>
          <li><img src={icon} alt="icon" className="list-icon" />
          Enhanced Security: Rest easy with live ride tracking and secure payment options.</li>
        </ul>
        {/* <div className="stats">
          <span>1.99k <strong>Users</strong></span>
          <span>5.74k <strong>Drivers</strong></span>
        </div> */}
            <p className="forun-ride-download-text">
            Book your ride now!
        </p>
        <div className="app-buttons">
          <a
            href="https://apps.apple.com/pk/app/forunpoint-food-gifts/id1630196197"
            target="_blank"
            rel="noreferrer"
          >
          <img src={AppStore} alt="App Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user"
            target="_blank"
            rel="noreferrer"
          >
          <img src={PlayStore} alt="Google Play" />
          </a>
        </div>
      </div>
      <div className="image-section">
        <img src="./images/forunride.png" alt="Forun Ride" />
      </div>
    </div>
  );
};

export default ForunRide;
