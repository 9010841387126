import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import './Autocomplete.css';
import { getMe, placeDetail, updateUser, autoComplete } from '../../../shared/apis';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';

function Autocomplete() {
    const [User, setUser] = useState([]);
    const [Adress, setAdress] = useState("Enter Your Delivery Address");
    const [items, setItems] = useState([]);
    const [uuid4, setUuid4] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [ExtractedCity, setExtractedCity] = useState("");

    const navigate = useNavigate();

    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
        if (user.data.user.streetAddress) {
            setAdress(user.data.user.streetAddress);
        } else if (userAddress) {
            setAdress(truncateString(userAddress.streetAddress));
        }
        setUuid4(uuid());
        const words = userAddress.streetAddress.replace(/,/g, "").split(" ");
        let city = words[words.length - 2];
        if (city.toLowerCase() === "city" && words.length > 2) {
            city = words[words.length - 3];
        }
        if (!isNaN(city) && words.length > 2) {
            city = words[words.length - 3];
        }
        setExtractedCity(city)
    };

    const showToastFunction = (e) => {
        if (Adress === "Enter Your Delivery Address") {
            e.preventDefault();

            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            navigate(`/restaurants/city/${ExtractedCity}`);
        }
    };

    const truncateString = (input) => {
        const regex = /[a-zA-Z]/g;
        const matches = input.match(regex);
        if (!matches || matches.length <= 45) {
            return input;
        }
        return input.substring(0, 45) + '...';
    };

    useEffect(() => {
        init();
    }, [ExtractedCity]);

    const handleOnSearch = async (string, results) => {
        let test = [];
        let result = await autoComplete(string, uuid4);
        for (let i = 0; i < result?.data?.places?.predictions.length; i++) {
            test.push({
                id: i,
                name: result?.data?.places?.predictions[i]?.description,
                placeId: result?.data?.places?.predictions[i]?.place_id,
            });
        }
        setItems(test);
    };

    const handleOnSelect = async (item) => {
        let place = await placeDetail(item.placeId, uuid4);
        let body = {
            streetAddress: place.data.places.result.formatted_address,
            location: {
                type: "Point",
                coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat],
            },
            address: {
                street: place.data.places.result.formatted_address,
            },
        };
        const words = body.streetAddress.replace(/,/g, "").split(" ");
        const country = words[words.length - 1];
        let city = words[words.length - 2];
        if (city.toLowerCase() === "city" && words.length > 2) {
            city = words[words.length - 3];
        }
        if (!isNaN(city) && words.length > 2) {
            city = words[words.length - 3];
        }
        setExtractedCity(city)
        localStorage.setItem('UserAddress', JSON.stringify(body));
        setAdress(body.streetAddress);
        if (User._id !== null) {
            await updateUser(User._id, body);
        }
    };

    const formatResult = (item) => {
        return (
            <>
                <span className='result-item-name'>{item.name}</span>
            </>
        );
    };

    return (
        <div>
            {showToast &&

                <div className="custom-toast">
                    <div className="custom-toast-header">
                        <strong className="custom-toast-title">Message</strong>
                        <button className="custom-toast-close">&times;</button>
                    </div>
                    <div className="custom-toast-body">
                        Please enter your address to continue.

                    </div>
                </div>

            }
            <div className='search-field' style={{ width: 512, position: 'sticky', zIndex: 100 }}>
                <img className="location-icon" src="./images/Vector.svg" alt="Location Icon" />
                <ReactSearchAutocomplete
                    styling={{
                        borderRadius: "5px !important",
                    }}
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    formatResult={formatResult}
                    placeholder={Adress}
                    showIcon={false}
                />
                <button className="input-group-text amber lighten-3" id="basic-text1" onClick={showToastFunction}>
                    Find Here
                </button>
            </div>
        </div>
    );
}

export default Autocomplete;
