import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./about-forunpoint.css";
const DvourSolution = () => {
  return (
    <div>
      <Navbar />
      <div className="about-forunpoint">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="about-forunpoint-card">
                <div class="card-body">
                  <h2 className="about-forunpoint-card1">
                    About ForunPoint
                  </h2>
                  <img
                    src="./images/about-underline.png"
                    alt="About-solution"
                    className="underline"
                  />
                  &nbsp;
                  <img
                    src="./images/about-underline-s.png"
                    alt="About-solution"
                    className="underline"
                  />
                  &nbsp;
                  <img
                    src="./images/about-underline-xs.png"
                    alt="About-solution"
                    className="underline"
                  />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12">
              <div class="about-forunpoint-card">
                <div class="card-body">
                  <img
                    className="about-forunpointimg"
                    src="./images/about-banner.png"
                    alt="About-solution"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-12">
              <div class="about-forunpoint-card">
                <div class="card-body">
                  <p>
                    Founded in the center of Frisco, Texas, ForunPoint has revolutionized how you connect with your family and friends. Imagine a world where you can order food, flowers, and gifts with a single tap on your smartphone.
                  </p>
                  <p>We bring happiness to your doorstep through joyful moments by offering reliable Pakistani food delivery apps. We are all about innovation, convenience, and creativity, so we’re infusing these core values into every aspect of our services to take your experience to the next level. Get ready to be intrigued by our innovative approach.</p>
                  <p>It’s our pleasure to present groundbreaking features created especially for you! Voice ordering allows you to order gifts from any location at any time—no more hassles with the phone when it is time to make someone’s day. Moreover, our video memo feature helps convey warm words that help maintain relationships with friends and family.</p>
                  <p>We are not just a food delivery service; we are a community. We are in synergy with our valued restaurant partners, providing the right solutions to help them grow their businesses. Our partners get better commission rates, no device integration, and SaaS solutions that help them deserve a competitive edge in the food industry.</p>
                  <p>Our dedicated team is passionate about connecting you with the best restaurants in Pakistan —the food capital of Pakistan! Whether you are a food lover seeking great food, a restaurant proprietor needing business expansion, or a rider willing to deliver joy, ForunPoint is here for you.</p>
                  <p>Join us on this exciting journey! As we look forward to expanding our services, we’re committed to bringing your desires closer than ever.</p>
                  <p><b>ForunPoint – Dream It, Tap It, Live It!</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DvourSolution;
