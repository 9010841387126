import FoodSection from "./food-section/food-section";
import AboutSection from "./about-section/about-section";
import DeliverySection from "./delivery-section/delivery-secion";
import DisheSection from "./dishes-section/dishes-section";
import CustomerApp from "./customer-app/customer-app";
import RestaurantApp from "./restaurant-app/restaurant-app";
import DriverApp from "./driver-app/driver-app";
import LandingHome from "./homepage/LandingHome";
import BlogSection from "./updates/updates";
import FaqSection from "./faq-section/faq-section";
import Divider from "../divider/divider"
import Features from "./features/features"
import { Helmet } from 'react-helmet';
import RideApp from './ride-app/ride-app'

const HomePage = () => {
  const currentUrl = window.location.href;
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>{process.env.REACT_APP_TITLE}</title>

        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
      </Helmet>
      <LandingHome />
      <FoodSection />
      <RideApp />
      <AboutSection />
      <DeliverySection />
      <DisheSection />
      {/* 
      <CustomerApp />
      <RestaurantApp />
      <DriverApp /> */}
      <Features />
      <Divider />
      <FaqSection />
      <BlogSection />
    </div>
  );
};

export default HomePage;