import React, { useEffect } from 'react';

const HtmlStitemap = () => {


  return (
    <div>
      <h1>Restaurant Sitemap</h1>
    <ul>
        <li><a href="https://forunpoint.com.pk/restaurant/the-burning-munch-663de6e02481c000236d8750">The Burning Munch</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/m21-pizza-663602d939dc0f00298eb95d">M21 Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/My-Burger-Barkat-Market-662608fee1afab0023de73cd">My Burger Barkat Market</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/dera-restaurant-65d343468b4bcd0023afc888">Dera Restaurant</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-ice-land-66347f77d0eea9002391d758">Fresh Ice Land</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/deli-bite-663b21e9934d8200297edd62">Deli Bite</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/ice-land-gulberg-3-663ca8ab98d5d90023d5e6d1">Ice Land Gulberg 3</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asali-banu-beef-pulao-663dd3e208369800291ff07f">Asali Banu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/gull-bhai-mutton-chanay-and-bong-paye-kozi-haleem-662f3b6234115e002493ae62">Gull Bhai Mutton Chanay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/rch-rumaisas-pizza-663213d9920e400023ee0f1b">RCH Rumaisa's Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/liberty-food-corner-&-fresh-juice-663889d2d3a3e200298b5773">Liberty Food Corner & Fresh Juice</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-juice-corner-model-town-662f723bcfa7b900239a4319">Fresh Juice Corner Model Town</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asad-maher-hotel-&-bbq-66349828d0eea900239226a4">Asad Maher Hotel & BBQ</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr.waffles-ice-cream-662f8456cfa7b900239aee29">Mr. Waffles Ice Cream</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/zaffrani-chai-6633326fe2ccdb0029e24dba">Zaffrani Chai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pehalwan-bannu-beef-pulao-6628ec541d02f800234ec2e5">Pehalwan Bannu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/captain-cook's-65e8a0530d063000231d57bb">Captain Cook's</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/chowmein-delight-house-662bce871ee8cf0023388879">Chowmein Delight House</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pappa-jee-happy-bhai-66360f4239dc0f00298ef301">Pappa Jee Happy Bhai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/snacpac-6635f2bb39dc0f00298e3769">Snacpac</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/punjab-garto-jalaybi-and-tawa-piece-663c7326fcc67200231882c7">Punjab Garto Jalaybi</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/shahenshah-dahi-bhallay-&-fruit-chaat-663f1a55924e2000298ff4d8">Shahenshah Dahi Bhallay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/wok-rock-chinese-663f1c52924e2000299013f8">Wok Rock Chinese</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/al-kuwait-shawarma-point-663f3d421fce620029f51960">Al Kuwait Shawarma Point</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr-burger-&-shawarma-663dc47d08369800291f7018">Mr. Burger & Shawarma</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/the-burning-munch-663de6e02481c000236d8750">The Burning Munch</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/m21-pizza-663602d939dc0f00298eb95d">M21 Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/My-Burger-Barkat-Market-662608fee1afab0023de73cd">My Burger Barkat Market</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/dera-restaurant-65d343468b4bcd0023afc888">Dera Restaurant</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-ice-land-66347f77d0eea9002391d758">Fresh Ice Land</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/deli-bite-663b21e9934d8200297edd62">Deli Bite</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/ice-land-gulberg-3-663ca8ab98d5d90023d5e6d1">Ice Land Gulberg 3</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asali-banu-beef-pulao-663dd3e208369800291ff07f">Asali Banu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/gull-bhai-mutton-chanay-and-bong-paye-kozi-haleem-662f3b6234115e002493ae62">Gull Bhai Mutton Chanay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/rch-rumaisas-pizza-663213d9920e400023ee0f1b">RCH Rumaisa's Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/liberty-food-corner-&-fresh-juice-663889d2d3a3e200298b5773">Liberty Food Corner & Fresh Juice</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-juice-corner-model-town-662f723bcfa7b900239a4319">Fresh Juice Corner Model Town</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asad-maher-hotel-&-bbq-66349828d0eea900239226a4">Asad Maher Hotel & BBQ</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr.waffles-ice-cream-662f8456cfa7b900239aee29">Mr. Waffles Ice Cream</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/zaffrani-chai-6633326fe2ccdb0029e24dba">Zaffrani Chai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pehalwan-bannu-beef-pulao-6628ec541d02f800234ec2e5">Pehalwan Bannu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/captain-cook's-65e8a0530d063000231d57bb">Captain Cook's</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/chowmein-delight-house-662bce871ee8cf0023388879">Chowmein Delight House</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pappa-jee-happy-bhai-66360f4239dc0f00298ef301">Pappa Jee Happy Bhai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/snacpac-6635f2bb39dc0f00298e3769">Snacpac</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/punjab-garto-jalaybi-and-tawa-piece-663c7326fcc67200231882c7">Punjab Garto Jalaybi</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/shahenshah-dahi-bhallay-&-fruit-chaat-663f1a55924e2000298ff4d8">Shahenshah Dahi Bhallay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/wok-rock-chinese-663f1c52924e2000299013f8">Wok Rock Chinese</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/al-kuwait-shawarma-point-663f3d421fce620029f51960">Al Kuwait Shawarma Point</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr-burger-&-shawarma-663dc47d08369800291f7018">Mr. Burger & Shawarma</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/the-burning-munch-663de6e02481c000236d8750">The Burning Munch</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/m21-pizza-663602d939dc0f00298eb95d">M21 Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/My-Burger-Barkat-Market-662608fee1afab0023de73cd">My Burger Barkat Market</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/dera-restaurant-65d343468b4bcd0023afc888">Dera Restaurant</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-ice-land-66347f77d0eea9002391d758">Fresh Ice Land</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/deli-bite-663b21e9934d8200297edd62">Deli Bite</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/ice-land-gulberg-3-663ca8ab98d5d90023d5e6d1">Ice Land Gulberg 3</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asali-banu-beef-pulao-663dd3e208369800291ff07f">Asali Banu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/gull-bhai-mutton-chanay-and-bong-paye-kozi-haleem-662f3b6234115e002493ae62">Gull Bhai Mutton Chanay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/rch-rumaisas-pizza-663213d9920e400023ee0f1b">RCH Rumaisa's Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/liberty-food-corner-&-fresh-juice-663889d2d3a3e200298b5773">Liberty Food Corner & Fresh Juice</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-juice-corner-model-town-662f723bcfa7b900239a4319">Fresh Juice Corner Model Town</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asad-maher-hotel-&-bbq-66349828d0eea900239226a4">Asad Maher Hotel & BBQ</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr.waffles-ice-cream-662f8456cfa7b900239aee29">Mr. Waffles Ice Cream</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/zaffrani-chai-6633326fe2ccdb0029e24dba">Zaffrani Chai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pehalwan-bannu-beef-pulao-6628ec541d02f800234ec2e5">Pehalwan Bannu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/captain-cook's-65e8a0530d063000231d57bb">Captain Cook's</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/chowmein-delight-house-662bce871ee8cf0023388879">Chowmein Delight House</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pappa-jee-happy-bhai-66360f4239dc0f00298ef301">Pappa Jee Happy Bhai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/snacpac-6635f2bb39dc0f00298e3769">Snacpac</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/punjab-garto-jalaybi-and-tawa-piece-663c7326fcc67200231882c7">Punjab Garto Jalaybi</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/shahenshah-dahi-bhallay-&-fruit-chaat-663f1a55924e2000298ff4d8">Shahenshah Dahi Bhallay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/wok-rock-chinese-663f1c52924e2000299013f8">Wok Rock Chinese</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/al-kuwait-shawarma-point-663f3d421fce620029f51960">Al Kuwait Shawarma Point</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr-burger-&-shawarma-663dc47d08369800291f7018">Mr. Burger & Shawarma</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/the-burning-munch-663de6e02481c000236d8750">The Burning Munch</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/m21-pizza-663602d939dc0f00298eb95d">M21 Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/My-Burger-Barkat-Market-662608fee1afab0023de73cd">My Burger Barkat Market</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/dera-restaurant-65d343468b4bcd0023afc888">Dera Restaurant</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-ice-land-66347f77d0eea9002391d758">Fresh Ice Land</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/deli-bite-663b21e9934d8200297edd62">Deli Bite</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/ice-land-gulberg-3-663ca8ab98d5d90023d5e6d1">Ice Land Gulberg 3</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asali-banu-beef-pulao-663dd3e208369800291ff07f">Asali Banu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/gull-bhai-mutton-chanay-and-bong-paye-kozi-haleem-662f3b6234115e002493ae62">Gull Bhai Mutton Chanay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/rch-rumaisas-pizza-663213d9920e400023ee0f1b">RCH Rumaisa's Pizza</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/liberty-food-corner-&-fresh-juice-663889d2d3a3e200298b5773">Liberty Food Corner & Fresh Juice</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/fresh-juice-corner-model-town-662f723bcfa7b900239a4319">Fresh Juice Corner Model Town</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/asad-maher-hotel-&-bbq-66349828d0eea900239226a4">Asad Maher Hotel & BBQ</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr.waffles-ice-cream-662f8456cfa7b900239aee29">Mr. Waffles Ice Cream</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/zaffrani-chai-6633326fe2ccdb0029e24dba">Zaffrani Chai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pehalwan-bannu-beef-pulao-6628ec541d02f800234ec2e5">Pehalwan Bannu Beef Pulao</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/captain-cook's-65e8a0530d063000231d57bb">Captain Cook's</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/chowmein-delight-house-662bce871ee8cf0023388879">Chowmein Delight House</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/pappa-jee-happy-bhai-66360f4239dc0f00298ef301">Pappa Jee Happy Bhai</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/snacpac-6635f2bb39dc0f00298e3769">Snacpac</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/punjab-garto-jalaybi-and-tawa-piece-663c7326fcc67200231882c7">Punjab Garto Jalaybi</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/shahenshah-dahi-bhallay-&-fruit-chaat-663f1a55924e2000298ff4d8">Shahenshah Dahi Bhallay</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/wok-rock-chinese-663f1c52924e2000299013f8">Wok Rock Chinese</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/al-kuwait-shawarma-point-663f3d421fce620029f51960">Al Kuwait Shawarma Point</a></li>
        <li><a href="https://forunpoint.com.pk/restaurant/mr-burger-&-shawarma-663dc47d08369800291f7018">Mr. Burger & Shawarma</a></li>
    </ul>
    </div>
  );
};

export default HtmlStitemap;
