import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import CookieConsent from "../../cookies-consent/cookies-consent";
import "./LandingHome.css";
import Autocomplete from '../Autocomplete/Autocomplete';
import carRide from '../../../assets/images/ride-service.png'
import allServices from '../../../assets/images/all-services.png'
import mobileDevice from '../../../assets/images/mobile-service.png'
import forunpointText from '../../../assets/images/forunpoint-text.png'
import forunBuddy from '../../../assets/images/gift-buddy-service.png'
import deliveryBoy from '../../../assets/images/delivery-service.png'
import JoinOptions from "../../join-us-compo/join-us-compo";
import { Helmet } from 'react-helmet';
import AppDawnloadPopup from "../../dialogs/app-dawnload-popup/app-popup"


const LandingHome = () => {
  const [showPopup, setShowPopup] = useState(true);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="landing-home-container">
      <JoinOptions />
      <Navbar />
      <div className="landing-page">
        <Helmet>
          <title>Fast & Reliable Food Delivery App in Pakistan | ForunPoint</title>
          <meta
            name="description"
            content="Get  best food delivery app ForunPoint in Pakistan. Enjoy fast, reliable service and various delicious meals delivered right to your doorstep. Download now!"
          />
        </Helmet>
        <div className="image-sec"></div>
        <div className="hero-container">
          <h1 className="hero-title">Ab Har Kaam Hoga Forun</h1>
          <p className="hero-subtitle">Ride Service | Food Delivery | Gift Delivery</p>
          
          <div className="mobile-app-banner">
            <img src={allServices} className="forunpoint-services" alt="Forun Point" />
          </div>
          {/* <div className="mobile-app-banner">
            <img src={mobileDevice} className="mobile-app" alt="Mobile App" />
          </div> */}

          {/* <div className="hero-elements">
            <img src={carRide} className="car-img" alt="Ride Service" />
            <img src={forunBuddy} className="forun-buddy" alt="Forun Buddy" />
            <img src={deliveryBoy} className="delivery-boy" alt="Delivery Boy" />
          </div> */}
        </div>



        {/* <div className="landing-content">
          <img src={forunpointText} alt="Forunpoint Text" className="forunpoint-text" />
          <h1 className="landing-heading">Food and Gift Delivery!</h1>
          <p className="landing-description">Chutki Bajate Forun Hazir!</p>
        </div>
        <img src={carRide} className="car-service" alt="Ride Service" />
        <img src={mobileDevice} className="mobile-app" alt="Mobile App" />
        <img src={forunBuddy} className="gift-service" alt="Forun Buddy" />
        <img src={deliveryBoy} className="delivery-service" alt="Delivery Boy" /> */}
        {/* <div className="row landingcard2">
          <div className="col-md-6 col-lg-5">
            <div className="landing-card">
              <div className="card-body">
                <div className="input-group md-form form-sm form-2 pl-0">
                  <Autocomplete />
                  <div className="input-group-append"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <CookieConsent />
      {showPopup && <AppDawnloadPopup onClose={handleClosePopup} />}
    </div>
  );
};

export default LandingHome;
